<template>
  <div class="container">

    <div style="margin-top: 2em;">
      <div style="font-weight: bold;">1. 구하고자 하는 항목을 선택하세요.</div>
      <div class="container" style="display: flex; margin-top: 1em;">      
        <label style="margin-left: 1em;">
          <input type="radio" v-model="selectedOption" value="pace" /> 페이스
        </label>

        <label>
          <input type="radio" v-model="selectedOption" value="distance" /> 거리
        </label>

        <label style="margin-left: 1em;">
          <input type="radio" v-model="selectedOption" value="time" /> 시간
        </label>
      </div>
    </div>

    <div style="margin-top: 2em;">
      <div style="margin-bottom: 1em; font-weight: bold;">2. 나머지 두 값을 형식에 맞게 입력하세요.</div>
      <div class="row">
        <div >거리(km)</div>
        <div v-if="selectedOption == 'distance'" class="output">{{ distanceInput }}km</div>
        <input v-else v-model="distanceInput" :readonly="isReadOnly('distance')" placeholder="예 : 42.195" @input="onDist" />      
      </div>

      <div class="row">
        <div>시간(hh:mm) </div>
        <div v-if="selectedOption == 'time'" class="output">{{ timeInput }}</div>
        <input v-else v-model="timeInput" :readonly="isReadOnly('time')" placeholder="예 : 3:30" @input="onTime" />      
      </div>

      <div class="row">
        <div>페이스(mm:ss) </div>
        <div v-if="selectedOption == 'pace'" class="output">{{ paceInput }}</div>
        <input v-else  v-model="paceInput" :readonly="isReadOnly('pace')" placeholder="예 : 6:30" @input="onPace" />
        <span v-if="paceValidationMessage">{{ paceValidationMessage }}</span>
      </div>

      <div style="margin: 2em 0 1em 0; font-size: 1em; color: darkslategrey;">
        <pre>
* 케이던스와 보폭은 페이스를 기반으로 상호반비례 관계입니다. 
* 본인의 신체조건과 목표에 맞게 최적값을 찾아 훈련하세요.

        </pre>
      </div>

      <div class="row">
        <div>케이던스(bpm) </div>
        <input v-model="cadenceInput" placeholder="예 : 180" @input="onCandence" />      
      </div>

      <div class="row">
        <div>보폭(meter) </div>
        <input v-model="footInput" placeholder="예 : 1.2" @input="onFoot" />      
      </div>

    </div>

    <div style="margin-top: 2em;">@eto2000</div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      selectedOption: "pace", // 선택한 항목을 저장하는 데이터 속성
      distanceInput: "42.195", // 거리 입력 값을 저장하는 데이터 속성
      paceInput: "05:41",     // 페이스 입력 값을 저장하는 데이터 속성
      timeInput: "4:00",      // 시간 입력 값을 저장하는 데이터 속성
      cadenceInput: 180,
      footInput: "1.2",
      paceValidationMessage: "", // 페이스 입력값 유효성 검증 메시지

      isUpdatedCadence: true, // 사용자가 케이던스를 수정했다. 즉,보폭만 계산한다.
    };
  },
  mounted() {
    this.calc()
  },
  methods: {
    getSeconds(){ // 총시간 sec
      const arr = this.timeInput.split(':')
      const hh = Number(arr[0]);
      const mm = Number(arr[1])
      const seconds = hh * 60 * 60 + mm * 60
      console.log('seconds', seconds)
      return seconds;
    },
    pace2Sec(){
      const arr = this.paceInput.split(':')
      const mm = arr[0];
      const ss = Number(arr[1])
      console.log(mm, ss)
      const seconds = (mm * 60) + ss;
      return seconds;
    },
    getSpeed(){ // 초속 : 1초에 몇킬로...
      const arr = this.paceInput.split(':')
      const mm = arr[0];
      const ss = Number(arr[1])
      console.log(mm, ss)
      const seconds = (mm * 60) + ss;
      console.log('seconds', seconds)
      return 1 / seconds;      
    },
    calc(){
      console.log(this.selectedOption)
      switch (this.selectedOption) {
        case 'distance':
          {            
            let secSpeed = this.getSpeed(); // 초속구하기 km
            let seconds = this.getSeconds();
            this.distanceInput = Math.floor(secSpeed * seconds * 100) / 100; 
          }
          break;
        case 'pace': // 걸린시간 / 거리
          {
            let seconds = this.getSeconds() / this.distanceInput;

            const secondsToMinutesAndSeconds = (seconds) => {
                var minutes = Math.floor(seconds / 60);
                var remainingSeconds = seconds % 60;

                // 두 자리 숫자로 포맷팅
                minutes = (minutes < 10) ? '0' + minutes : minutes;
                remainingSeconds = (remainingSeconds < 10) ? '0' + remainingSeconds : remainingSeconds;
                remainingSeconds = Math.floor(remainingSeconds)
                // remainingSeconds = remainingSeconds?.toFixed(2)

                return minutes + ':' + remainingSeconds;
            }            
            console.log('paceInput', this.paceInput)

            this.paceInput = secondsToMinutesAndSeconds(seconds)
          }    
          break;
        case 'time':
          {
            let secSpeed = this.getSpeed(); // 초속구하기 sec/km
            const seconds = this.distanceInput / secSpeed 

            const secondsToHourAndMin = (seconds) => {
                var hours = Math.floor(seconds / 3600);
                var minutes = Math.floor((seconds % 3600) / 60);

                // 두 자리 숫자로 포맷팅
                hours = (hours < 10) ? '0' + hours : hours;
                minutes = (minutes < 10) ? '0' + minutes : minutes;

                return hours + ':' + minutes;
            }      
            this.timeInput = secondsToHourAndMin(seconds)            
          }
          break;
      
        default:
          break;
      }

      // 케이던스 보폭
      {
        console.log('this.isUpdatedCadence', this.isUpdatedCadence)
        if(this.isUpdatedCadence) // 케이던스를 수정한 경우 (디폴트)
        {
          // 거리 = 케이던스 * 보폭 * 60  // 거리 = (60 / 페이스) 
          console.log('1분에 ?m>', this.getSpeed() * 1000 * 60)

          // this.getSpeed() * 1000 * 60 = this.cadenceInput * this.footInput
          this.footInput = ((this.getSpeed() * 1000 * 60) / this.cadenceInput).toFixed(2);
        }
        else
        {
          this.cadenceInput = ((this.getSpeed() * 1000 * 60) / this.footInput).toFixed(2);
        }
      }
    },
    onPace(){
      this.calc()
    },
    onDist(){
      this.calc()
    },
    onTime(){
      this.calc()
    },   
    onCandence(){
      this.isUpdatedCadence = true;
      this.calc()
    },
    onFoot(){
      this.isUpdatedCadence = false;
      this.calc()
    },
    validatePaceFormat() {
      const paceRegex = /^\d{1,2}:\d{2}$/;
      if (!paceRegex.test(this.paceInput)) {
        this.paceValidationMessage = "올바른 형식이 아닙니다. (예: 6:30)";
      } else {
        this.paceValidationMessage = "";
      }
    },    
    // 선택한 항목에 따라 readonly 상태를 반환하는 메서드
    isReadOnly(option) {
      return this.selectedOption == option;
    }
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.row{
  display: flex;
  margin-bottom: 1em;
  justify-content: space-between;
}

.container {
  margin: auto;
  width: fit-content;
}

input {
  text-align:  right;
  margin-left: 1em;
}

.output{
  margin-left: 1em;
  font-weight: bold;
}

input {
  background: white;
  padding: 5px;
  /* width: 100%; */
  /* height: 2em; */
  font-size: 1em;
  /* outline: none; */
  border: 1px solid lightgray; 
  /* width: calc(100% - 1em);  */
  border: none; 
  border-bottom: 1px solid gray;  
  zoom: 1;
}
</style>
